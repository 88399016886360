<script setup lang="ts">
import { photoSlideshowSchema } from '#imports'

useBlockFormSchema(photoSlideshowSchema)

const { t } = useI18n({ useScope: 'local' })

const { fields, push } = useFieldArray('images')

function addImage() {
  push({
    fileUrl: '',
  })
}
</script>

<template>
  <div class="flex flex-col gap-y-4 pt-4">
    <BaseParagraph class="mt-0">
      {{ t('explanation') }}
    </BaseParagraph>

    <div class="flex flex-col gap-y-4">
      <BaseHeading as="h3" class="text-lg font-bold">
        Foto's
      </BaseHeading>

      <div
        v-for="(image, index) in fields"
        :key="index"
        class="border-primary-500 relative flex flex-col gap-y-3 rounded-xl border p-4"
      >
        <FormImageField
          :name="`images[${index}]`"
          label="Foto"
          keep-value
        />
      </div>
    </div>

    
    <BaseButton @click="addImage">
      {{ t('addButton') }}
    </BaseButton>
  </div>
</template>

<i18n lang="json">
{
  "en": {
    "explanation": "Upload multiple photos to create a horizontal slideshow.",
    "imagesLabel": "Photos",
    "addButton": "Add extra photo"
  },
  "nl": {
    "explanation": "Upload meerdere foto's om een horizontale diavoorstelling te maken.",
    "imagesLabel": "Foto's",
    "addButton": "Extra foto toevoegen"
  }
}
</i18n>
