<script setup lang="ts">
import { mixed, object, string } from 'yup'

// Schema definition
const fourCardsSchema = object({
  mainActionLabel: string().optional(),
  mainActionPath: string().optional(),
  title1: string().optional(),
  title2: string().optional(),
  title3: string().optional(),
  title4: string().optional(),
  image1: mixed<File | ContentFile>().optional(),
  image2: mixed<File | ContentFile>().optional(),
  image3: mixed<File | ContentFile>().optional(),
  image4: mixed<File | ContentFile>().optional(),
  body1: string().optional(),
  body2: string().optional(),
  body3: string().optional(),
  body4: string().optional(),
  buttonText1: string().optional(),
  buttonText2: string().optional(),
  buttonText3: string().optional(),
  buttonText4: string().optional(),
  buttonUrl1: string().optional(),
  buttonUrl2: string().optional(),
  buttonUrl3: string().optional(),
  buttonUrl4: string().optional(),
})

// Inject the block form schema
useBlockFormSchema(fourCardsSchema)

const { t } = useI18n({ useScope: 'local' })
</script>

<template>
  <div class="flex flex-col gap-y-4 pt-4">
    
    <div class="border-muted-200 rounded-xl border p-4">
      <BaseHeading as="h3" class="mb-4">
        {{ t('mainAction') }}
      </BaseHeading>
      <FormField
        name="mainActionLabel"
        :label="t('mainActionLabel')"
        placeholder="Bijv: Bekijk alle klussen"
      />
      <FormField
        name="mainActionPath"
        :label="t('mainActionPath')"
        placeholder="Bijv: /vacaturebank"
      />
    </div>

    
    <div
      v-for="index in 4"
      :key="index"
      class="border-muted-200 rounded-xl border p-4"
    >
      <BaseHeading as="h3" class="mb-4">
        {{ t('card', { number: index }) }}
      </BaseHeading>

      <div class="flex flex-col gap-y-4">
        <FormImageField
          :name="`image${index}`"
          :label="t('image')"
        />
        <FormField
          :name="`title${index}`"
          :label="t('title')"
          :placeholder="t('titlePlaceholder')"
        />
        <FormField
          :name="`body${index}`"
          :label="t('body')"
          component="textarea"
          :placeholder="t('bodyPlaceholder')"
        />
        <FormField
          :name="`buttonText${index}`"
          :label="t('buttonText')"
          :placeholder="t('buttonTextPlaceholder')"
        />
        <FormField
          :name="`buttonUrl${index}`"
          :label="t('buttonUrl')"
          :placeholder="t('buttonUrlPlaceholder')"
        />
      </div>
    </div>
  </div>
</template>

<i18n lang="json">
{
  "en": {
    "mainAction": "Main Action",
    "mainActionLabel": "Main action text",
    "mainActionPath": "Main action destination (page URL)",
    "card": "Card {number}",
    "image": "Image",
    "title": "Title",
    "titlePlaceholder": "Enter title...",
    "body": "Text",
    "bodyPlaceholder": "Enter text...",
    "buttonText": "Button text",
    "buttonTextPlaceholder": "e.g. Read more",
    "buttonUrl": "Button destination (URL)",
    "buttonUrlPlaceholder": "e.g. /volunteer-bank"
  },
  "nl": {
    "mainAction": "Hoofdactie",
    "mainActionLabel": "Hoofdactie tekst",
    "mainActionPath": "Hoofdactie bestemming (pagina URL)",
    "card": "Kaart {number}",
    "image": "Afbeelding",
    "title": "Titel",
    "titlePlaceholder": "Vul hier de titel in...",
    "body": "Tekst",
    "bodyPlaceholder": "Vul hier de tekst in...",
    "buttonText": "Knop tekst",
    "buttonTextPlaceholder": "Bijv: Lees meer",
    "buttonUrl": "Knop bestemming (URL)",
    "buttonUrlPlaceholder": "Bijv: /vacaturebank"
  }
}
</i18n>
