<script setup lang="ts">
import { photoHeroSchema } from '#imports'

useBlockFormSchema(photoHeroSchema)

const { t } = useI18n({ useScope: 'local' })
</script>

<template>
  <div class="flex flex-col gap-y-4 pt-4">
    <BaseParagraph class="mt-0">
      {{ t('explanation') }}
    </BaseParagraph>

    <FormImageField
      name="image"
      :label="t('imageLabel')"
      keep-value
      required
    />

    <FormField
      name="slogan"
      :label="t('sloganLabel')"
      required
    />

    <FormCheckboxField
      name="showLogoMark"
      :label="t('showLogoMarkLabel')"
    />
  </div>
</template>

<i18n lang="json">
{
  "en": {
    "explanation": "Create a full-width hero section with a background image and slogan text.",
    "imageLabel": "Background Image",
    "sloganLabel": "Slogan text",
    "showLogoMarkLabel": "Show logo mark"
  },
  "nl": {
    "explanation": "Maak een hero sectie met een achtergrondafbeelding en slogantekst.",
    "imageLabel": "Achtergrondafbeelding",
    "sloganLabel": "Slogan tekst",
    "showLogoMarkLabel": "Toon logo beeldmerk"
  }
}
</i18n>
