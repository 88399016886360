<script setup lang="ts">
// TODO:
// - The i18n for styling options are duplicated from the DynamicCards component, from which this component is derived.
//   This should be refactored.

import { object, string, number, boolean } from 'yup'
import { DynamicCardComponentStyle, ContentButtonVariant } from '#imports'

const { t } = useI18n({ useScope: 'local' })

// Exclude default pages
const newsTypes = Object.values(PageType).filter(type => type != PageType.Default)

// Schema definition
const newsCarouselSchema = object({
  title: string().label(t('fields.title')),
  maxItems: number().min(1).max(20).default(6).required().label(t('fields.maxItems')),
  showDate: boolean().default(true).label(t('fields.showDate')),
  showSummary: boolean().default(true).label(t('fields.showSummary')),
  pageType: string().oneOf(newsTypes).default(PageType.News).required().label(t('fields.pageType')),
  styling: string().oneOf(Object.values(DynamicCardComponentStyle)).default(DynamicCardComponentStyle.Default).label(t('fields.styling')),
  buttonVariant: string().oneOf(Object.values(ContentButtonVariant)).default(ContentButtonVariant.Primary).label(t('fields.buttonVariant')),
})

// Inject the block form schema
useBlockFormSchema(newsCarouselSchema)
</script>

<template>
  <div class="flex flex-col gap-y-4 pt-4">
    <BaseParagraph class="mt-0">
      {{ t('explanation') }}
    </BaseParagraph>

    <FormField name="title" :label="t('fields.title')" />

    <FormListField
      name="pageType"
      :label="t('fields.pageType')"
      :items="newsTypes"
      :label-factory="item => $t(`page.attributes.pageTypeOptions.${item}`)"
    />

    <FormField
      name="maxItems"
      :label="t('fields.maxItems')"
      type="number"
      min="1"
      max="20"
    />

    <FormCheckboxField
      name="showDate"
      :label="t('fields.showDate')"
    />

    <FormCheckboxField
      name="showSummary"
      :label="t('fields.showSummary')"
    />

    <FormListField
      name="styling"
      :items="Object.values(DynamicCardComponentStyle)"
      :label="t('fields.styling')"
      :label-factory="(option) => t(`stylingOptions.${option}`)"
    />

    <FormListField
      name="buttonVariant"
      :items="Object.values(ContentButtonVariant)"
      :label="t('fields.buttonVariant')"
      :label-factory="(option) => t(`buttonVariantOptions.${option}`)"
    />
  </div>
</template>

<i18n lang="json">
{
  "en": {
    "explanation": "This block displays the latest news items in a horizontal scrollable carousel.",
    "fields": {
      "title": "Title (optional)",
      "maxItems": "Maximum number of news items",
      "showDate": "Show date",
      "showSummary": "Show summary",
      "pageType": "Type of items",
      "styling": "Card style",
      "buttonVariant": "Button style"
    },
    "stylingOptions": {
      "default": "Standard (image as header)",
      "photo-background": "Image as background",
      "dark-background": "Dark (with image as header)",
      "text-only": "Text (no image)"
    },
    "buttonVariantOptions": {
      "primary": "Primary",
      "secondary": "Secondary",
      "outline": "Outline",
      "outline-secondary": "Outline secondary",
      "light": "Light",
      "white": "White",
      "arrow": "Arrow"
    }
  },
  "nl": {
    "explanation": "Dit blok toont de nieuwste nieuwsberichten in een horizontaal scrollbare carousel.",
    "fields": {
      "title": "Titel (optioneel)",
      "maxItems": "Maximaal aantal nieuwsberichten",
      "showDate": "Toon datum",
      "showSummary": "Toon samenvatting",
      "pageType": "Type items",
      "styling": "Kaart stijl",
      "buttonVariant": "Knop stijl"
    },
    "stylingOptions": {
      "default": "Standaard (afbeelding als header)",
      "photo-background": "Afbeelding als achtergrond",
      "dark-background": "Donker (met afbeelding als header)",
      "text-only": "Tekst (geen afbeelding)"
    },
    "buttonVariantOptions": {
      "primary": "Primary",
      "secondary": "Secondary",
      "outline": "Outline",
      "outline-secondary": "Outline secondary",
      "light": "Light",
      "white": "White",
      "arrow": "Arrow"
    }
  }
}
</i18n>
