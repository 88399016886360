<script setup lang="ts">
import { YouTubeWidth } from '#imports'

const { t } = useI18n({ useScope: 'local' })

useBlockFormSchema(youtubeBlockSchema)

const { fields, push, remove } = useFieldArray<YouTubeVideo>('youtubeVideos')

const pushNew = () => {
  push({ url: '', title: '', description: '', width: YouTubeWidth.Small })
}

if (fields.value.length === 0) {
  pushNew()
}

// Flag to allow adding multiple video's. This is not finished yet, that's why I added this flag.
const allowMultiple = false
</script>

<template>
  <div class="flex flex-col gap-y-4 pt-4">
    <BaseParagraph class="mt-0">
      Voeg een YouTube video toe.
    </BaseParagraph>

    <BaseMessage
      v-if="fields.length === 0"
      type="info"
    >
      Je hebt nog geen video's toegevoegd.
    </BaseMessage>

    <div
      v-for="(video, index) in fields"
      :key="index"
      class="border-primary-500 relative flex flex-col gap-y-3 rounded-xl border p-4"
    >
      <div v-if="allowMultiple" class="flex items-center justify-between">
        <BaseHeading
          tag="h4"
          size="lg"
          weight="medium"
        >
          Video {{ index + 1 }}
        </BaseHeading>

        <BaseButtonIcon
          color="danger"
          shape="curved"
          muted
          class="scale-75"
          @click="remove(index)"
        >
          <Icon name="ph:trash" class="size-5" />
        </BaseButtonIcon>
      </div>

      <FormField :name="`youtubeVideos[${index}].url`" label="YouTube URL" />
      
      
      <!-- <FormField
        :name="`youtubeVideos[${index}].width`"
        label="Breedte (optioneel)"
        placeholder="Bijv. w-full"
      /> -->

      <Field
        v-slot="{ field, errorMessage }"
        :name="`youtubeVideos[${index}].width`"
      >
        <BaseListbox
          :items="Object.values(YouTubeWidth)"
          :properties="{
            label: (value) => t(`widths.${value}`),
          }"
          label="Breedte (optioneel)"
          :error="errorMessage"
          v-bind="field"
        />
      </Field>

      <div
        v-if="video.value.url"
        class="relative flex w-full items-center justify-center rounded-xl bg-gray-200 p-4"
      >
        <BaseParagraph
          class="text-muted-500 absolute left-3 top-2"
          size="xs"
        >
          Voorbeeld:
        </BaseParagraph>
        <ContentBlockViewPartialYouTubeVideo :video="video.value" />
      </div>
    </div>

    <BaseButton
      v-if="allowMultiple"
      color="primary"
      @click="pushNew"
    >
      Voeg video toe
    </BaseButton>
  </div>
</template>

<i18n lang="json">
{
  "en": {
    "widths": {
      "small": "Small",
      "medium": "Medium",
      "large": "Large"
    }
  },
  "nl": {
    "widths": {
      "small": "Klein",
      "medium": "Middel",
      "large": "Groot"
    }
  }
}
</i18n>
