<script setup lang="ts">
import { mixed, object, string, number, array } from 'yup'
import { ContentFileImageVariant } from '#imports'

enum ImageBlockVariant {
  Default = 'default',
  FullWidth = 'full-width',
  LeftAligned = 'left-aligned',
}

enum ImageBlockCaptionColor {
  Default = 'default',
  White = 'white',
}

const { t } = useI18n()

// Schema definition
const imageSchema = object({
  // Primary image
  image: mixed<File | ContentFile>().required(),
  // Additional images for slideshow
  additionalImages: array().of(
    object({
      image: mixed<File | ContentFile>().required(),
      altText: string().optional(),
    }),
  ).optional(),
  altText: string().required(),
  caption: string().optional(),
  captionColor: string().oneOf(Object.values(ImageBlockCaptionColor)).optional(),
  variant: string().oneOf(Object.values(ImageBlockVariant)).default(ImageBlockVariant.Default),
  sizeVariant: string()
    .oneOf(Object.values(ContentFileImageVariant))
    .default(ContentFileImageVariant.Original),
  maxWidth: number().optional(),
  // Slideshow settings
  transitionInterval: number().default(5),
  transitionStyle: string().oneOf(['fade', 'slide']).default('fade'),
})

// Inject the block form schema
useBlockFormSchema(imageSchema)
</script>

<template>
  <div class="flex flex-col gap-y-4 pt-4">
    
    <FormImageField
      name="image"
      :label="t('image')"
    />

    
    <FormListField
      name="variant"
      :label="t('variant.label')"
      :items="Object.values(ImageBlockVariant)"
      :label-factory="(variant) => t('variant.options.' + variant)"
    />
    <FormField
      name="altText"
      :label="t('altText.label')"
      :placeholder="t('altText.placeholder')"
    />
    <FormField
      name="caption"
      :label="t('caption.label')"
      :placeholder="t('caption.placeholder')"
    />
    <FormListField
      name="captionColor"
      :label="t('captionColor.label')"
      :items="Object.values(ImageBlockCaptionColor)"
      :label-factory="(color) => t('captionColor.options.' + color)"
    />

    <FormListField
      name="sizeVariant"
      :label="t('sizeVariant.label')"
      :items="Object.values(ContentFileImageVariant)"
      :label-factory="(variant) => t('sizeVariant.options.' + variant)"
    />

    <FormField
      name="maxWidth"
      :label="t('maxWidth.label')"
      :placeholder="t('maxWidth.placeholder')"
    />

    
    <div class="border-primary-200 bg-primary-50 mt-2 flex flex-col gap-y-4 border-t p-4">
      <BaseHeading size="sm" class="mb-2">
        {{ t('additionalImages.label') }}
      </BaseHeading>

      <BaseMessage type="info">
        {{ t('additionalImages.explanation') }}
      </BaseMessage>

      
      <FormImageField
        name="additionalImages[0].image"
        :label="t('additionalImages.image2')"
      />
      <FormField
        name="additionalImages[0].altText"
        :label="t('additionalImages.altText')"
        :placeholder="t('altText.placeholder')"
      />

      
      <FormImageField
        name="additionalImages[1].image"
        :label="t('additionalImages.image3')"
      />
      <FormField
        name="additionalImages[1].altText"
        :label="t('additionalImages.altText')"
        :placeholder="t('altText.placeholder')"
      />

      
      <FormField
        name="transitionInterval"
        type="number"
        :label="t('slideshow.interval.label')"
        :placeholder="t('slideshow.interval.placeholder')"
      />
      <FormListField
        name="transitionStyle"
        :label="t('slideshow.style.label')"
        :items="['fade', 'slide']"
        :label-factory="(style) => t('slideshow.style.options.' + style)"
      />
    </div>
  </div>
</template>

<i18n lang="json">
{
  "en": {
    "image": "Image",
    "altText": {
      "label": "Image description (alt text for Google)",
      "placeholder": "E.g. Photo of volunteer"
    },
    "additionalImages": {
      "label": "Additional Images (for slideshow)",
      "explanation": "If you want to make a slideshow, you can add up to 2 additional images. Make sure the images have more or less the same height and width.",
      "image2": "Second Image",
      "image3": "Third Image",
      "altText": "Alt Text"
    },
    "slideshow": {
      "interval": {
        "label": "Transition interval (seconds)",
        "placeholder": "5"
      },
      "style": {
        "label": "Transition style",
        "options": {
          "fade": "Fade",
          "slide": "Slide"
        }
      }
    },
    "caption": {
      "label": "Caption (optional)",
      "placeholder": "E.g. Volunteer in action!"
    },
    "captionColor": {
      "label": "Caption color (optional)",
      "options": {
        "default": "Default",
        "white": "White"
      }
    },
    "variant": {
      "label": "Variant",
      "placeholder": "Default, Full width, Left aligned",
      "options": {
        "default": "Default (centered)",
        "full-width": "Full width",
        "left-aligned": "Left aligned"
      }
    },
    "sizeVariant": {
      "label": "Image size",
      "options": {
        "original": "Original",
        "large": "Large",
        "medium": "Medium",
        "small": "Small",
        "thumb": "Thumbnail"
      }
    },
    "maxWidth": {
      "label": "Max width (in pixels)",
      "placeholder": "E.g. 500"
    }
  },
  "nl": {
    "image": "Afbeelding",
    "altText": {
      "label": "Beschrijving van afbeelding (alt tekst voor Google)",
      "placeholder": "Bijv: Foto van vrijwilliger"
    },
    "additionalImages": {
      "label": "Extra afbeeldingen (voor slideshow)",
      "explanation": "Als je echt uit wilt pakken kun je een slideshow maken met 1 of 2 extra afbeeldingen. Zorg wel dat de afbeeldingen ongeveer dezelfde grootte hebben.",
      "image2": "Tweede afbeelding",
      "image3": "Derde afbeelding",
      "altText": "Alt tekst"
    },
    "slideshow": {
      "interval": {
        "label": "Transitie interval (seconden)",
        "placeholder": "5"
      },
      "style": {
        "label": "Transitiestijl",
        "options": {
          "fade": "Vervagen",
          "slide": "Schuiven"
        }
      }
    },
    "caption": {
      "label": "Tekst bij afbeelding (optioneel)",
      "placeholder": "Bijv: vrijwilliger in actie!"
    },
    "captionColor": {
      "label": "Kleur tekst (optioneel)",
      "options": {
        "default": "Standaard",
        "white": "Wit"
      }
    },
    "variant": {
      "label": "Stijlvariant",
      "placeholder": "Standaard, Volledige breedte, Links uitgelijnd",
      "options": {
        "default": "Standaard (gecentreerd)",
        "full-width": "Volledige breedte",
        "left-aligned": "Links uitgelijnd"
      }
    },
    "sizeVariant": {
      "label": "Afbeeldinggrootte",
      "options": {
        "original": "Originele afbeeldingsgrootte",
        "large": "Groot",
        "medium": "Gemiddeld",
        "small": "Klein",
        "thumb": "Thumbnail"
      }
    },
    "maxWidth": {
      "label": "Maximale breedte in pixels (bij voorkeur leeg laten)",
      "placeholder": "Bijv: 500"
    }
  }
}
</i18n>
