<script setup lang="ts">
import { ContentFileImageVariant, tryImageVariant } from '#imports'

defineProps<{
  image: ContentFile
  slogan: string
  showLogoMark?: boolean
  breakOut?: boolean
}>()

const { cardRadiusClass } = useThemeClasses()

</script>

<template>
  <ContentBlockViewPartialContainer
    class="relative h-[500px] w-full overflow-hidden"
    :class="cardRadiusClass"
  >
    
    <img
      :src="tryImageVariant(image, ContentFileImageVariant.Large)"
      :alt="slogan"
      class="absolute inset-0 size-full object-cover"
    >

    
    <div class="absolute inset-0 bg-black/30" />

    
    <div class="relative flex h-full flex-col items-center justify-center px-4">
      <PublicLogoMark v-if="showLogoMark" class="max-h-16" />

      
      <h1 class="text-center text-4xl font-bold text-white md:text-6xl">
        {{ slogan }}
      </h1>
    </div>
  </ContentBlockViewPartialContainer>
</template>
