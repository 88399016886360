import { array, object, string } from 'yup'
import type { InferType } from 'yup'
import { quoteSchema } from './quoteBlock'

export const uspSchema = object({
  title: string().required(),
})

export const callToActionSchema = object({
  text: string(),
  usps: array().of(uspSchema).default([]),
  quote: quoteSchema.nullable().default(null),
  buttonText: string().default('Ik wil me aanmelden'),
  buttonUrl: string().required(),
})

export type CallToActionPayload = InferType<typeof callToActionSchema>
export type UspPayload = InferType<typeof uspSchema>
