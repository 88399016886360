<script setup lang="ts">
import { string, object, boolean } from 'yup'

const { t } = useI18n({ useScope: 'local' })

// Schema with the contact form identifier
const schema = object().shape({
  formId: string().required(),
  alignLeft: boolean().optional().default(false),
})

useBlockFormSchema(schema)

</script>

<template>
  <div class="flex flex-col gap-y-4">
    <FormField name="formId" :label="t('formId')" />
    <FormCheckboxField name="alignLeft" :label="t('alignLeft')" />
  </div>
</template>

<i18n>
{
  "en": {
    "formId": "Formulier ID",
    "alignLeft": "Align left"
  },
  "nl": {
    "formId": "Formulier ID",
    "alignLeft": "Links uitlijnen"
  }
}
</i18n>
