import { array, object, string } from 'yup'
import type { InferType } from 'yup'

export const hotlinkSchema = object({
  title: string().required(),
  url: string().required(),
})

export const hotlinkSectionSchema = object({
  title: string().default('Sectie titel'),
  color: string().oneOf(['primary', 'secondary']).default('primary'),
  hotlinks: array().of(hotlinkSchema).default([]),
})

export const hotlinksSchema = object({
  sections: array().of(hotlinkSectionSchema).required(),
})

export type HotlinkPayload = InferType<typeof hotlinksSchema>
export type HotlinkSectionPayload = InferType<typeof hotlinkSectionSchema>
export type HotlinkItemPayload = InferType<typeof hotlinkSchema>
