<script setup lang="ts">
const { colorClass = 'text-theme-content' } = defineProps<{
  title?: string
  colorClass?: string
}>()

const contentBlockTitleSize = inject('contentBlockTitleSize', '4xl')

</script>

<template>
  <BaseHeading
    v-if="title"
    as="h3"
    :size="contentBlockTitleSize"
    class="col-span-full"
    :class="colorClass"
  >
    {{ title }}
  </BaseHeading>
</template>
