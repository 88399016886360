<script setup lang="ts">
import { quoteBlockSchema } from '#imports'

// Inject the schema
useBlockFormSchema(quoteBlockSchema)

const { fields, push, remove } = useFieldArray('quotes')

const { t } = useI18n({ useScope: 'local' })
</script>

<template>
  <div class="flex flex-col gap-y-4 pt-4">
    <BaseParagraph class="mt-0">
      {{ t('explanation') }}
    </BaseParagraph>

    <FormField
      name="title"
      :label="t('titleLabel')"
    />

    <FormImageField
      name="image"
      :label="t('mainPhotoLabel')"
      required
    />

    <BaseMessage v-if="fields.length === 0" type="info">
      {{ t('noQuotesAdded') }}
    </BaseMessage>

    <div
      v-for="(quote, index) in fields"
      :key="index"
      class="border-primary-500 relative flex flex-col gap-y-3 rounded-xl border p-4"
    >
      <div class="flex items-center justify-between">
        <BaseHeading
          tag="h4"
          size="lg"
          weight="medium"
        >
          {{ t('quote') }} {{ index + 1 }}
        </BaseHeading>

        <BaseButtonIcon
          color="danger"
          shape="curved"
          muted
          class="scale-75"
          @click="remove(index)"
        >
          <Icon name="ph:trash" class="size-5" />
        </BaseButtonIcon>
      </div>

      <FormField
        :name="`quotes[${index}].text`"
        :label="t('quoteTextLabel')"
        type="textarea"
        required
      />
      <FormField
        :name="`quotes[${index}].author`"
        :label="t('authorLabel')"
        required
      />
      <FormField
        :name="`quotes[${index}].jobTitle`"
        :label="t('jobTitleLabel')"
      />
      <FormImageField
        :name="`quotes[${index}].photo`"
        :label="t('authorPhotoLabel')"
      />
    </div>

    <BaseButton
      color="primary"
      @click="push({ text: '', author: '' })"
    >
      {{ t('addQuote') }}
    </BaseButton>
  </div>
</template>

<i18n lang="json">
{
  "en": {
    "explanation": "Create a split block with a photo on the left and quotes on the right.",
    "mainPhotoLabel": "Main Photo (Left Side)",
    "titleLabel": "Title (Optional)",
    "quote": "Quote",
    "noQuotesAdded": "You haven't added any quotes yet.",
    "addQuote": "Add a quote",
    "quoteTextLabel": "Quote Text",
    "authorLabel": "Author Name",
    "jobTitleLabel": "Job Title (Optional)",
    "authorPhotoLabel": "Author Photo (Optional)"
  },
  "nl": {
    "explanation": "Maak een gesplitst blok met een foto links en citaten rechts.",
    "mainPhotoLabel": "Hoofdfoto (Linkerkant)",
    "titleLabel": "Titel (Optioneel)",
    "quote": "Citaat",
    "noQuotesAdded": "Je hebt nog geen citaten toegevoegd.",
    "addQuote": "Voeg een citaat toe",
    "quoteTextLabel": "Citaat Tekst",
    "authorLabel": "Naam Auteur",
    "jobTitleLabel": "Functietitel (Optioneel)",
    "authorPhotoLabel": "Foto Auteur (Optioneel)"
  }
}
</i18n>
