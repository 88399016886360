<script setup lang="ts">
const { tenant } = useTenant()
// for legacy
const applyTopPadding = tenant.value == 'public'
</script>

<template>
  <section class="relative mx-auto w-full" :class="{ 'pt-4': applyTopPadding }">
    <slot />
  </section>
</template>
