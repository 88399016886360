<script setup lang="ts">
// TODO:
// - see todo notes below about width of caption
// - also think about a negative bottom margin to create overlay effect for the next section
// - dark image overlay for the large variant? (or a flag for it?)
// -

import { ContentFileImageVariant, tryImageVariant } from '#imports'

const { image,
  altText = '',
  caption = '',
  captionColor = 'default',
  variant = 'default',
  // By default, limit to large size to avoid loading huge images.
  sizeVariant = ContentFileImageVariant.Large,
  maxWidth,
  additionalImages = [],
  transitionInterval = 5,
  transitionStyle = 'fade',
} = defineProps<{
  image: ContentFile
  altText?: string
  caption?: string
  captionColor?: 'default' | 'white'
  // 'centered' would be a better name instead of 'default', but we need to data-migrate if we change this.
  variant?: 'default' | 'full-width' | 'left-aligned'
  sizeVariant?: ContentFileImageVariant
  maxWidth?: number
  additionalImages?: Array<{
    image: ContentFile
    altText?: string
    captionColor?: 'default' | 'white'
  }>
  transitionInterval?: number
  transitionStyle?: 'fade' | 'slide'
}>()

// Create an array of all images for the slideshow
const allImages = computed(() => {
  const primaryImage = { image, altText }
  return [primaryImage, ...additionalImages].filter(img => !!img.image)
})

// Check if slideshow should be enabled
const isSlideshow = computed(() => allImages.value.length > 1)

// Current image index for slideshow
const currentImageIndex = ref(0)

// Interval for slideshow
const interval = ref<NodeJS.Timeout>()

// We prefer the large image variant, but fall back to the original file URL.
const imageVariantUrl = computed(() => {
  const currentImage = allImages.value[currentImageIndex.value]
  return tryImageVariant(currentImage.image, sizeVariant)
})

// Get current image's URL by index
const getImageVariantUrl = (img: { image: ContentFile }) => {
  return tryImageVariant(img.image, sizeVariant)
}

// Get the current alt text
const currentAltText = computed(() => {
  return allImages.value[currentImageIndex.value].altText || altText
})

const imgStyle = computed(() => {
  if (maxWidth) {
    return `max-width: ${maxWidth}px`
  }
  return ''
})

// Hide the caption on mobile, because it's too small to be readable.
// We could add a flag for it, but it's not a priority.
const hideCaptionOnMobile = true

// Setup slideshow interval when component is mounted
onMounted(() => {
  if (isSlideshow.value) {
    interval.value = setInterval(() => {
      currentImageIndex.value = (currentImageIndex.value + 1) % allImages.value.length
    }, transitionInterval * 1000)
  }
})

// Clear interval when component is unmounted
onUnmounted(() => {
  if (interval.value) {
    clearInterval(interval.value)
  }
})

// Reference to primary image for dimensions
const primaryImageRef = ref<HTMLImageElement | null>(null)
const containerStyle = ref({
  paddingBottom: '56.25%', // Default 16:9 aspect ratio
})

// Set container dimensions based on primary image after it loads
const updateContainerDimensions = () => {
  if (primaryImageRef.value) {
    const img = primaryImageRef.value
    const aspectRatio = (img.naturalHeight / img.naturalWidth) * 100
    containerStyle.value.paddingBottom = `${aspectRatio}%`
  }
}
</script>

<template>
  <ContentBlockViewPartialContainer
    class=""
    :class="{
      'col-span-12 flex flex-col items-center justify-center': variant === 'default',
      'col-span-12 flex flex-col items-start justify-center': variant === 'left-aligned',
    }"
  >
    <template v-if="variant === 'default' || variant === 'left-aligned'">
      
      <div
        v-if="variant === 'default'"
        class="relative grid grid-cols-12"
      >
        
        <div
          v-if="isSlideshow"
          class="relative col-span-12 w-full md:col-span-8 md:col-start-3"
          :style="containerStyle"
        >
          
          <img
            ref="primaryImageRef"
            :src="getImageVariantUrl({ image })"
            class="pointer-events-none absolute opacity-0"
            alt="Hidden reference image"
            @load="updateContainerDimensions"
          >

          <TransitionGroup
            tag="div"
            class="absolute inset-0 size-full"
            :enter-active-class="`transition-all duration-1000`"
            :enter-from-class="transitionStyle === 'fade' ? 'opacity-0' : 'opacity-0 translate-x-full'"
            :enter-to-class="transitionStyle === 'fade' ? 'opacity-100' : 'opacity-100 translate-x-0'"
            :leave-active-class="`transition-all duration-1000 absolute inset-0`"
            :leave-from-class="transitionStyle === 'fade' ? 'opacity-100' : 'opacity-100 translate-x-0'"
            :leave-to-class="transitionStyle === 'fade' ? 'opacity-0' : 'opacity-0 -translate-x-full'"
          >
            <img
              v-for="(img, index) in allImages"
              v-show="currentImageIndex === index"
              :key="img.image.id"
              :src="getImageVariantUrl(img)"
              :alt="img.altText || altText"
              class="absolute inset-0 size-full rounded-xl object-cover"
              :style="imgStyle"
            >
          </TransitionGroup>
        </div>

        
        <img
          v-else
          :src="imageVariantUrl"
          class="col-span-12 rounded-xl md:col-span-8 md:col-start-3"
          :alt="currentAltText"
          :style="imgStyle"
        >
      </div>

      
      <div v-else class="relative">
        
        <div
          v-if="isSlideshow"
          class="relative w-full"
          :style="containerStyle"
        >
          
          <img
            ref="primaryImageRef"
            :src="getImageVariantUrl({ image })"
            class="pointer-events-none absolute opacity-0"
            alt="Hidden reference image"
            @load="updateContainerDimensions"
          >

          <TransitionGroup
            tag="div"
            class="absolute inset-0 size-full"
            :enter-active-class="`transition-all duration-1000`"
            :enter-from-class="transitionStyle === 'fade' ? 'opacity-0' : 'opacity-0 translate-x-full'"
            :enter-to-class="transitionStyle === 'fade' ? 'opacity-100' : 'opacity-100 translate-x-0'"
            :leave-active-class="`transition-all duration-1000 absolute inset-0`"
            :leave-from-class="transitionStyle === 'fade' ? 'opacity-100' : 'opacity-100 translate-x-0'"
            :leave-to-class="transitionStyle === 'fade' ? 'opacity-0' : 'opacity-0 -translate-x-full'"
          >
            <img
              v-for="(img, index) in allImages"
              v-show="currentImageIndex === index"
              :key="img.image.id"
              :src="getImageVariantUrl(img)"
              :alt="img.altText || altText"
              class="absolute inset-0 size-full rounded-xl object-cover"
              :style="imgStyle"
            >
          </TransitionGroup>
        </div>

        
        <img
          v-else
          :src="imageVariantUrl"
          class="rounded-xl"
          :alt="currentAltText"
          :style="imgStyle"
        >
      </div>

      
      <span v-if="caption" class="text-muted-400 mt-1 text-sm">{{
        caption
      }}</span>

      
      <div v-if="isSlideshow" class="mt-2 flex justify-center space-x-2">
        <button
          v-for="(_, index) in allImages"
          :key="index"
          class="size-2 rounded-full transition-colors"
          :class="currentImageIndex === index ? 'bg-primary-500' : 'bg-gray-300'"
          aria-label="Go to slide"
          @click="currentImageIndex = index"
        />
      </div>
    </template>

    <div v-else-if="variant === 'full-width'" class="relative">
      <div class="absolute left-12 top-12 z-10 md:max-w-xs" :class="{ 'hidden md:block': hideCaptionOnMobile }">
        <ContentBlockViewPartialTitle
          v-if="caption"
          :title="caption"
          :color-class="captionColor == 'white' ? 'text-white' : ''"
        />
      </div>

      
      <div
        v-if="isSlideshow"
        class="relative w-full"
        :style="containerStyle"
      >
        
        <img
          ref="primaryImageRef"
          :src="getImageVariantUrl({ image })"
          class="pointer-events-none absolute opacity-0"
          alt="Hidden reference image"
          @load="updateContainerDimensions"
        >

        <TransitionGroup
          tag="div"
          class="absolute inset-0 size-full"
          :enter-active-class="`transition-all duration-1000`"
          :enter-from-class="transitionStyle === 'fade' ? 'opacity-0' : 'opacity-0 translate-x-full'"
          :enter-to-class="transitionStyle === 'fade' ? 'opacity-100' : 'opacity-100 translate-x-0'"
          :leave-active-class="`transition-all duration-1000 absolute inset-0`"
          :leave-from-class="transitionStyle === 'fade' ? 'opacity-100' : 'opacity-100 translate-x-0'"
          :leave-to-class="transitionStyle === 'fade' ? 'opacity-0' : 'opacity-0 -translate-x-full'"
        >
          <img
            v-for="(img, index) in allImages"
            v-show="currentImageIndex === index"
            :key="img.image?.id"
            :src="getImageVariantUrl(img)"
            :alt="img.altText || altText"
            class="absolute inset-0 size-full rounded-xl object-cover"
            :style="imgStyle"
          >
        </TransitionGroup>
      </div>

      
      <img
        v-else
        :src="imageVariantUrl"
        :alt="currentAltText"
        class="w-full rounded-xl"
        :style="imgStyle"
      >

      
      <div v-if="isSlideshow" class="absolute inset-x-0 bottom-4 flex justify-center space-x-2">
        <button
          v-for="(_, index) in allImages"
          :key="index"
          class="size-2 rounded-full bg-white bg-opacity-70 transition-colors"
          :class="currentImageIndex === index ? 'bg-opacity-100' : 'bg-opacity-50'"
          aria-label="Go to slide"
          @click="currentImageIndex = index"
        />
      </div>
    </div>
  </ContentBlockViewPartialContainer>
</template>
