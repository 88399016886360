<script setup lang="ts">
import { ContentFileImageVariant, tryImageVariant } from '#imports'

const { image, alt } = defineProps<{ image: ContentFile, alt?: string }>()
</script>

<template>
  <div class="relative col-span-6 overflow-hidden py-4 pr-4 pt-[100%]">
    <img
      :src="tryImageVariant(image, ContentFileImageVariant.Medium)"
      class="absolute left-0 top-0 size-full object-cover"
      :alt="alt"
    >
  </div>
</template>
