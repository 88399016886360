<script setup lang="ts">
useBlockFormSchema(callToActionSchema)

const { t } = useI18n({ useScope: 'local' })

const { fields: usps, push: addUsp, remove: removeUsp } = useFieldArray('usps')

function addNewUsp() {
  addUsp({
    title: '',
  })
}

const { value: quote, setValue: setQuote } = useField<CallToActionQuotePayload | null>('quote')

const showQuoteForm = ref(!!quote.value)

function toggleQuoteForm() {
  if (showQuoteForm.value) {
    // Set the quote object to null and hide the form
    setQuote(null)
    showQuoteForm.value = false
  }
  else {
    // Initialize quote object when adding the section
    setQuote({
      text: '',
      author: '',
      jobTitle: undefined,
      avatar: undefined,
    })
    showQuoteForm.value = true
  }
}
</script>

<template>
  <div class="flex flex-col gap-y-4 pt-4">
    <BaseParagraph class="mt-0">
      {{ t('explanation') }}
    </BaseParagraph>

    <div class="space-y-4">
      <BaseHeading as="h3" class="text-lg font-bold">
        {{ t('mainContent') }}
      </BaseHeading>

      <div class="rounded-lg border border-gray-200 p-4">
        <FormField
          name="text"
          :label="t('text')"
          type="textarea"
        />
      </div>
    </div>

    <div class="space-y-4">
      <BaseHeading as="h3" class="text-lg font-bold">
        {{ t('uspsTitle') }}
      </BaseHeading>

      <div
        v-for="(usp, index) in usps"
        :key="index"
        class="relative flex flex-col gap-y-3 rounded-lg border border-gray-200 p-4"
      >
        <div class="flex items-center justify-between">
          <BaseHeading as="h4" class="text-base font-semibold">
            {{ t('usp', { number: index + 1 }) }}
          </BaseHeading>

          <BaseButtonIcon
            color="danger"
            @click="removeUsp(index)"
          >
            <Icon name="lucide:trash" class="size-4" />
          </BaseButtonIcon>
        </div>

        <FormField
          :name="`usps.${index}.title`"
          :label="t('uspTitle')"
        />
      </div>

      <BaseButton
        variant="ghost"
        @click="addNewUsp"
      >
        {{ t('addUsp') }}
      </BaseButton>
    </div>

    <div class="space-y-4">
      <div class="flex items-center justify-between">
        <BaseHeading as="h3" class="text-lg font-bold">
          {{ t('quoteTitle') }}
        </BaseHeading>

        <BaseButton
          variant="ghost"
          :color="showQuoteForm ? 'danger' : 'primary'"
          @click="toggleQuoteForm"
        >
          {{ showQuoteForm ? t('removeQuote') : t('addQuote') }}
        </BaseButton>
      </div>

      <div
        v-if="showQuoteForm"
        class="rounded-lg border border-gray-200 p-4"
      >
        <FormField
          name="quote.text"
          :label="t('quoteText')"
          type="textarea"
        />
        <FormField
          name="quote.author"
          :label="t('quoteAuthor')"
        />
        <FormField
          name="quote.jobTitle"
          :label="t('quoteJobTitle')"
        />
        <FormImageField
          name="quote.photo"
          :label="t('quotePhoto')"
          keep-value
        />
      </div>
    </div>

    <div class="space-y-4">
      <BaseHeading as="h3" class="text-lg font-bold">
        {{ t('buttonTitle') }}
      </BaseHeading>

      <div class="rounded-lg border border-gray-200 p-4">
        <FormField
          name="buttonText"
          :label="t('buttonText')"
        />
        <FormField
          name="buttonUrl"
          :label="t('buttonUrl')"
          type="url"
        />
      </div>
    </div>
  </div>
</template>

<i18n lang="json">
{
  "en": {
    "explanation": "Create a call-to-action section with optional text, USPs, and quote. The layout will adjust based on the content provided.",
    "mainContent": "Main Content",
    "text": "Text",
    "uspsTitle": "USPs (optional)",
    "usp": "USP {number}",
    "uspTitle": "USP Text",
    "addUsp": "Add USP",
    "quoteTitle": "Quote",
    "addQuote": "Add Quote",
    "removeQuote": "Remove Quote",
    "quoteText": "Quote Text",
    "quoteAuthor": "Author Name",
    "quoteJobTitle": "Job Title",
    "quotePhoto": "Author Photo",
    "buttonTitle": "Call-to-action Button",
    "buttonText": "Button Text",
    "buttonUrl": "Button URL"
  },
  "nl": {
    "explanation": "Maak een call-to-action sectie met optionele tekst, USP's en quote. De layout past zich aan op basis van de ingevulde content.",
    "mainContent": "Hoofdinhoud",
    "text": "Tekst",
    "uspsTitle": "USP's (optioneel)",
    "usp": "USP {number}",
    "uspTitle": "USP Tekst",
    "addUsp": "USP Toevoegen",
    "quoteTitle": "Quote",
    "addQuote": "Quote Toevoegen",
    "removeQuote": "Quote Verwijderen",
    "quoteText": "Quote Tekst",
    "quoteAuthor": "Naam Auteur",
    "quoteJobTitle": "Functie",
    "quotePhoto": "Foto Auteur",
    "buttonTitle": "Call-to-action Knop",
    "buttonText": "Knop Tekst",
    "buttonUrl": "Knop URL"
  }
}
</i18n>
