<script setup lang="ts">
import { ContentFileImageVariant, tryImageVariant } from '#imports'

const props = defineProps<{
  mainActionLabel?: string
  mainActionPath?: string
  title1?: string
  title2?: string
  title3?: string
  title4?: string
  image1?: ContentFile
  image2?: ContentFile
  image3?: ContentFile
  image4?: ContentFile
  body1?: string
  body2?: string
  body3?: string
  body4?: string
  buttonText1?: string
  buttonText2?: string
  buttonText3?: string
  buttonText4?: string
  buttonUrl1?: string
  buttonUrl2?: string
  buttonUrl3?: string
  buttonUrl4?: string
}>()

interface CardContent {
  title?: string
  body?: string
  image?: ContentFile
  buttonText?: string
  buttonUrl?: string
}

const cards: CardContent[] = [
  {
    title: props.title1,
    body: props.body1,
    image: props.image1,
    buttonText: props.buttonText1,
    buttonUrl: props.buttonUrl1,
  },
  {
    title: props.title2,
    body: props.body2,
    image: props.image2,
    buttonText: props.buttonText2,
    buttonUrl: props.buttonUrl2,
  },
  {
    title: props.title3,
    body: props.body3,
    image: props.image3,
    buttonText: props.buttonText3,
    buttonUrl: props.buttonUrl3,
  },
  {
    title: props.title4,
    body: props.body4,
    image: props.image4,
    buttonText: props.buttonText4,
    buttonUrl: props.buttonUrl4,
  },
]

// Filter the cards to count how many have content
const validCards = cards.filter(card =>
  Object.values(card).some(val => val),
)

const cardCount = ref(validCards.length)

// Determine grid column class based on number of valid cards
const gridColClass = computed(() => {
  switch (cardCount.value) {
    case 2:
      return 'col-span-3'
    case 3:
      return 'col-span-6 md:col-span-4'
    default:
      return 'col-span-3'
  }
})
</script>

<template>
  <ContentBlockViewPartialContainer
    :class="mainActionLabel ? 'pt-12' : 'pt-14'"
    class="pb-14"
  >
    <div v-if="mainActionLabel" class="flex w-full flex-col items-end pb-4">
      <TextButtonLink
        class="pr-0 pt-4"
        :to="mainActionPath"
        icon="ph:arrow-right-bold"
      >
        {{ mainActionLabel }}
      </TextButtonLink>
    </div>

    <div class="grid grid-cols-6 gap-6 md:grid-cols-12">
      
      <div v-if="cardCount === 2" class="col-span-3 hidden md:block" />

      <BaseCard
        v-for="(card, index) in validCards"
        :key="index"
        :class="gridColClass"
        class="overflow-hidden"
        shape="curved"
      >
        <div class="flex h-full flex-col">
          <div v-if="card.image?.fileUrl">
            
            <div class="relative mx-auto w-full overflow-hidden pt-[62.68%]">
              <img
                class="absolute left-0 top-0 size-full object-cover"
                :src="tryImageVariant(card.image, ContentFileImageVariant.Medium)"
                :alt="card.title"
              >
            </div>
          </div>

          <div
            class="flex grow flex-col justify-between gap-y-3 px-5 pb-6 pt-4"
          >
            <BaseHeading
              size="md"
              as="h4"
              class="mt-1.5"
            >
              {{ card.title }}
            </BaseHeading>

            <TextParagraph
              v-if="card.body"
              size="sm"
              color="light"
            >
              {{ card.body }}
            </TextParagraph>

            
            <div class="mt-auto">
              <BaseButton
                v-if="card.buttonText"
                color="primary"
                class="mt-2"
                :href="card.buttonUrl"
              >
                {{ card.buttonText }}
              </BaseButton>
            </div>
          </div>
        </div>
      </BaseCard>

      
      <div v-if="cardCount === 2" class="col-span-3 hidden md:block" />
    </div>
  </ContentBlockViewPartialContainer>
</template>
