<script setup lang="ts">
import { object, string } from 'yup'
import { BaseRichText } from '#components'

// Schema definition
const markdownSchema = object({
  body: string().required(),
})

// Inject the block form schema
useBlockFormSchema(markdownSchema)

const { t } = useI18n({ useScope: 'local' })
</script>

<template>
  <div class="flex flex-col gap-y-4 pt-4">
    <Field v-slot="{ field, errorMessage }" name="body">
      <BaseRichText
        v-bind="field"
        :error="errorMessage"
        :label="t('body')"
        :placeholder="t('bodyPlaceholder')"
      />
    </Field>
  </div>
</template>

<i18n lang="json">
{
  "en": {
    "body": "Text",
    "bodyPlaceholder": "Enter text..."
  },
  "nl": {
    "body": "Tekst",
    "bodyPlaceholder": "Vul hier de tekst in..."
  }
}
</i18n>
