<script setup lang="ts">
import { object, string, boolean } from 'yup'

const { t } = useI18n({ useScope: 'local' })

const sectionTitleSchema = object({
  title: string().required().label(t('fields.title')),
  size: string().oneOf(['xl', '2xl', '3xl', '4xl']).default('4xl').label(t('fields.size')),
  alignment: string().oneOf(['left', 'center']).default('left').label(t('fields.alignment')),
  showLogoMark: boolean().default(false).label(t('fields.showLogoMark')),
})

useBlockFormSchema(sectionTitleSchema)
</script>

<template>
  <div class="flex flex-col gap-y-4 pt-4">
    <FormField name="title" :label="t('fields.title')" />

    <FormListField
      name="size"
      :label="t('fields.size')"
      :items="['xl', '2xl', '3xl', '4xl']"
      :label-factory="(value) => t(`sizes.${value}`)"
    />

    <FormListField
      name="alignment"
      :label="t('fields.alignment')"
      :items="['left', 'center']"
      :label-factory="(value) => t(`alignment.${value}`)"
    />

    <FormCheckboxField
      name="showLogoMark"
      :label="t('fields.showLogoMark')"
    />

    <FormListField
      name="backgroundColor"
      :label="t('fields.backgroundColor')"
      :items="['white', 'primary', 'secondary', 'muted', 'transparent']"
      :label-factory="(value) => t(`backgroundColors.${value}`)"
    />
  </div>
</template>

<i18n lang="json">
{
  "en": {
    "fields": {
      "title": "Title",
      "size": "Text Size",
      "alignment": "Text Alignment",
      "showLogoMark": "Show Logo Mark",
      "backgroundColor": "Background Color"
    },
    "sizes": {
      "xl": "Extra large (xl)",
      "2xl": "Double extra large (2xl)",
      "3xl": "Triple extra large (3xl)",
      "4xl": "Quadruple extra large (4xl)"
    },
    "alignment": {
      "left": "Left",
      "center": "Center"
    },
    "backgroundColors": {
      "white": "White",
      "primary": "Primary",
      "secondary": "Secondary",
      "muted": "Muted",
      "transparent": "Transparent"
    }
  },
  "nl": {
    "fields": {
      "title": "Titel",
      "size": "Tekstgrootte",
      "alignment": "Tekst Uitlijning",
      "showLogoMark": "Toon Beeldmerk",
      "backgroundColor": "Achtergrondkleur"
    },
    "sizes": {
      "xl": "Extra groot (xl)",
      "2xl": "Dubbel extra groot (2xl)",
      "3xl": "Drie maal extra groot (3xl)",
      "4xl": "Viermaal extra groot (4xl)"
    },
    "alignment": {
      "left": "Links",
      "center": "Gecentreerd"
    },
    "backgroundColors": {
      "white": "Wit",
      "primary": "Primair",
      "secondary": "Secundair",
      "muted": "Muted",
      "transparent": "Transparant"
    }
  }
}
</i18n>
