<script setup lang="ts">
import { boolean, mixed, object, string } from 'yup'
import { ImageWithButtonsBlockStyles } from '#imports'

// Schema definition
const imageTextButtonsSchema = object({
  styling: string()
    .oneOf(Object.values(ImageWithButtonsBlockStyles))
    .required(),
  image: mixed<File | ContentFile>().optional(),
  isSwapped: boolean().optional(),
  headingText: string().required(),
  bodyText: string().required(),
  primaryButtonText: string().optional(),
  primaryButtonUrl: string().optional(),
  secondaryButtonText: string().optional(),
  secondaryButtonUrl: string().optional(),
})

// Inject the block form schema
useBlockFormSchema(imageTextButtonsSchema)

const { t } = useI18n({ useScope: 'local' })
</script>

<template>
  <div class="flex flex-col gap-y-4 pt-4">
    <FormListField
      name="styling"
      :label="t('styling')"
      component="select"
      :items="Object.values(ImageWithButtonsBlockStyles)"
    />

    <FormImageField
      name="image"
      :label="t('image')"
    />

    <FormCheckboxField
      name="isSwapped"
      :label="t('isSwapped')"
    />

    <FormField
      name="headingText"
      :label="t('headingText')"
      :placeholder="t('headingTextPlaceholder')"
    />

    <FormTextarea
      name="bodyText"
      :label="t('bodyText')"
      :placeholder="t('bodyTextPlaceholder')"
    />

    <div class="border-muted-200 rounded-xl border p-4">
      <BaseHeading as="h3" class="mb-4">
        {{ t('primaryButton') }}
      </BaseHeading>
      <div class="flex flex-col gap-y-4">
        <FormField
          name="primaryButtonText"
          :label="t('buttonText')"
          :placeholder="t('buttonTextPlaceholder')"
        />
        <FormField
          name="primaryButtonUrl"
          :label="t('buttonUrl')"
          :placeholder="t('buttonUrlPlaceholder')"
        />
      </div>
    </div>

    <div class="border-muted-200 rounded-xl border p-4">
      <BaseHeading as="h3" class="mb-4">
        {{ t('secondaryButton') }}
      </BaseHeading>
      <div class="flex flex-col gap-y-4">
        <FormField
          name="secondaryButtonText"
          :label="t('buttonText')"
          :placeholder="t('buttonTextPlaceholder')"
        />
        <FormField
          name="secondaryButtonUrl"
          :label="t('buttonUrl')"
          :placeholder="t('buttonUrlPlaceholder')"
        />
      </div>
    </div>
  </div>
</template>

<i18n lang="json">
{
  "en": {
    "styling": "Style",
    "image": "Image",
    "isSwapped": "Image on right",
    "headingText": "Title",
    "headingTextPlaceholder": "Enter title...",
    "bodyText": "Text",
    "bodyTextPlaceholder": "Enter text...",
    "primaryButton": "Primary Button",
    "secondaryButton": "Secondary Button",
    "buttonText": "Button text",
    "buttonTextPlaceholder": "e.g. Read more",
    "buttonUrl": "Button destination (URL)",
    "buttonUrlPlaceholder": "e.g. /volunteer-bank"
  },
  "nl": {
    "styling": "Stijl",
    "image": "Afbeelding",
    "isSwapped": "Afbeelding rechts",
    "headingText": "Titel",
    "headingTextPlaceholder": "Vul hier de titel in...",
    "bodyText": "Tekst",
    "bodyTextPlaceholder": "Vul hier de tekst in...",
    "primaryButton": "Primaire Knop",
    "secondaryButton": "Secundaire Knop",
    "buttonText": "Knop tekst",
    "buttonTextPlaceholder": "Bijv: Lees meer",
    "buttonUrl": "Knop bestemming (URL)",
    "buttonUrlPlaceholder": "Bijv: /vacaturebank"
  }
}
</i18n>
