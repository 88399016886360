<script setup lang="ts">
// This doesn't work apparently: `defineProps<YouTubeBlockPayload>()`
const props = defineProps<{
  youtubeVideos: YouTubeVideo[]
}>()
</script>

<template>
  <ContentBlockViewPartialContainer class="flex w-full flex-col items-center gap-4 py-4">
    <ContentBlockViewPartialYouTubeVideo
      v-for="(video, index) in props.youtubeVideos"
      :key="index"
      :video="video"
    />
  </ContentBlockViewPartialContainer>
</template>
