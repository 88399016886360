<script setup lang="ts">
import { DynamicCardComponentStyle, ContentButtonVariant } from '#imports'

const {
  title = undefined,
  maxItems = 3,
  showDate = true,
  showSummary = true,
  pageType = PageType.News,
  styling = DynamicCardComponentStyle.DarkBackground,
  buttonVariant = ContentButtonVariant.Outline,
} = defineProps<{
  title?: string
  maxItems?: number
  showDate?: boolean
  showSummary?: boolean
  pageType?: PageType
  styling?: DynamicCardComponentStyle
  buttonVariant?: ContentButtonVariant
}>()

const { t } = useI18n()

const { data: newsItems, status, error } = await fetchNews(pageType)

// Convert news items to dynamic cards format
const dynamicCards = computed(() => {
  if (!newsItems.value) return []

  return newsItems.value.slice(0, maxItems || 100).map(item => ({
    title: item.title,
    // Date as subtitle
    subTitle: showDate && item.createdAt ? formatDate(new Date(item.createdAt)) : undefined,
    body: showSummary && item.summary ? item.summary : undefined,
    mediaType: DynamicCardMediaType.Image,
    // TODO: let the API return a ContentFile object
    image: item.headerImageUrl
      ? {
          fileUrl: item.headerImageUrl,
          imageVariantUrls: {},
        } as ContentFile
      : undefined,
    buttonText: t('readMore'),
    buttonUrl: `/nieuws/${item.slug}`,
    // Add date as subtitle if needed
  }))
})

const { d } = useI18n()

// Helper function to format dates
const formatDate = (date: Date) => {
  if (!date) return undefined
  return d(date, 'date')
}
</script>

<template>
  <div v-if="status === 'pending'" class="flex justify-center py-8">
    <BasePlaceload class="h-8 w-full" />
  </div>

  <div v-else-if="error" class="text-danger-500 py-4">
    {{ $t('errors.loadingFailed') }}
  </div>

  <div v-else-if="newsItems?.length === 0" class="py-4 text-gray-500">
    {{ t('noItems') }}
  </div>

  <ContentBlockViewDynamicCards
    v-else
    :title="title"
    :cards="dynamicCards"
    :is-carousel="false"
    :styling="styling || DynamicCardComponentStyle.DarkBackground"
    :button-variant="buttonVariant || ContentButtonVariant.Outline"
    :max-cards-per-row="maxItems"
  />
</template>

<style scoped>
.scrollbar-hide::-webkit-scrollbar {
  display: none;
}
.scrollbar-hide {
  -ms-overflow-style: none;
  scrollbar-width: none;
}
</style>

<i18n lang="json">
{
  "en": {
    "noItems": "No news items found",
    "readMore": "Read more"
  },
  "nl": {
    "noItems": "Geen nieuwsberichten gevonden",
    "readMore": "Lees meer"
  }
}
</i18n>
