// Not sure why this doesn't auto-import
// import type { ContentBlock, ContentBlockPayload } from '@/schemas/contentBlocks'

export interface ContentBlock extends ApiEntity {
  id: id
  blockType: string
  position: number
  pageId: id
  data: {
    [key: string]: any
  }
}

interface ContentBlockPayload {
  [key: string]: any
}

export async function createContentBlock(attributes: ContentBlockPayload) {
  const { data } = await $api<JSONAPIResponse<ContentBlock>>(
    '/api/v1/cms/content_blocks',
    {
      method: 'POST',
      body: {
        data: {
          type: 'content_block',
          attributes: attributes,
        },
      },
    },
  )

  return data.attributes
}

export async function updateContentBlock(
  id: id,
  attributes: ContentBlockPayload,
) {
  const { data } = await $api<JSONAPIResponse<ContentBlock>>(
    `/api/v1/cms/content_blocks/${id}`,
    {
      method: 'PUT',
      body: {
        data: {
          type: 'content_block',
          attributes: attributes,
        },
      },
    },
  )

  return data.attributes
}

export async function moveContentBlockUp(id: id) {
  return await $api<JSONAPIResponse<ContentBlock>>(
    `/api/v1/cms/content_blocks/${id}/move_up`,
    {
      method: 'PUT',
      deserialize: true,
    },
  )
}

export async function moveContentBlockDown(id: id) {
  return await $api<JSONAPIResponse<ContentBlock>>(
    `/api/v1/cms/content_blocks/${id}/move_down`,
    {
      method: 'PUT',
      deserialize: true,
    },
  )
}

export async function deleteContentBlock(id: id) {
  await $api<JSONAPIResponse<ContentBlock>>(
    `/api/v1/cms/content_blocks/${id}`,
    {
      method: 'DELETE',
    },
  )
}
