<script setup lang="ts">
// TODO:
// - Set rounded corners based on theme default?
// - Padding p-8? Is that the default for this "theme"?
// - What kind of hover effect for links?

// A section to render two blocks with "hotlinks".
// Each block has a title and a list of links.
// A link can have a title, url, and an optional emoji or icon.

export interface Hotlink {
  title: string
  url: string
}

export interface HotlinkSection {
  title: string
  color: 'primary' | 'secondary'
  hotlinks: Hotlink[]
}

const { sections = [
  {
    title: '',
    color: 'primary',
    hotlinks: [],
  },
  {
    title: '',
    color: 'secondary',
    hotlinks: [],
  },
] } = defineProps<{
  sections?: HotlinkSection[]
}>()

const padding = inject<string>('contentBlockPadding', 'p-8')

const { cardRadiusClass } = useThemeClasses()

</script>

<template>
  <ContentBlockViewPartialContainer
    class="group/hotlinks grid grid-cols-1 gap-4 md:grid-cols-2"
    background-color="transparent"
  >
    <div
      v-for="section in sections"
      :key="section.title"
      class="space-y-4 text-white"
      :class="[`bg-${section.color}-500`, padding, cardRadiusClass]"
    >
      <BaseHeading
        as="h3"
        size="3xl"
        weight="extrabold"
      >
        {{ section.title }}
      </BaseHeading>
      <nav class="flex flex-col gap-2">
        <NuxtLink
          v-for="link in section.hotlinks"
          :key="link.title"
          :to="link.url"
          class="group/hotlink flex items-center justify-between border-b border-white/20 text-white hover:text-white/80"
        >
          {{ link.title }}

          <Icon name="ph:arrow-up-right" class="text-white transition-all duration-300 ease-out group-hover/hotlink:translate-x-1 group-hover/hotlink:translate-y-[-4px]" />
        </NuxtLink>
      </nav>
    </div>
  </ContentBlockViewPartialContainer>
</template>
