<script setup lang="ts">
import { ContentButtonVariant } from '#imports'

// TODO:
// - Determine based on theme config if the buttons should be rounded or not.
// - Maybe this component can be removed after upgrading Tairo, that has better button features.
// - Maybe rename it to "ThemeButton" or "ThemedButton"?
// - I was using bg-secondary-600 and bg-primary-600 for the hover state, but those didn't work
//   (for Oldebroek at least)
//   maybe we need to define default hover colors for the different variantsin the theme config?

const { variant = ContentButtonVariant.Primary, hoverPrefix = 'hover:' } = defineProps<{
  variant?: ContentButtonVariant
  // The prefix to use for group-hover, e.g. 'group-hover:' or 'group-hover/card:'
  hoverPrefix?: string
}>()

const variantClass = computed(() => {
  switch (variant) {
    case 'primary':
      return `bg-primary-500 text-white ${hoverPrefix}bg-secondary-500 font-semibold border-none`

    case 'secondary':
      return `bg-secondary-500 text-white ${hoverPrefix}bg-primary-500 font-semibold border-none`

    // Style used in the VacancyCategories block, on a colored background.
    case 'outline':
      return `bg-transparent border-white/80 text-white ${hoverPrefix}bg-white/10`

    case 'outline-secondary':
      return `bg-transparent border-secondary-500 text-secondary-500 ${hoverPrefix}bg-secondary-500/10`

    // Light background with primary text color.
    // Only use this on a colored background.
    case 'light':
      // Note that I made the text darker.
      return `bg-white/80 bg-transparent text-primary-700 ${hoverPrefix}bg-white/70`

    // White background with secondary text color.
    case 'white':
      return `bg-white text-secondary-500 ${hoverPrefix}bg-white/80 font-semibold border border-secondary-500 ${hoverPrefix}bg-secondary-500/10`

    case 'arrow':
      return `bg-transparent theme-text-content font-semibold border-none`
  }
})

// TODO: fix this. This really sucks. New tailwind version?
// Hack to make sure tailwind prerenders the hover classes.
const prerenderClasses = [
  `hover:bg-primary-500`,
  `hover:bg-secondary-500`,
  `hover:bg-white`,
  `hover:bg-transparent`,
  `hover:bg-secondary-500/10`,
  `hover:bg-white/10`,
  `hover:bg-white/80`,
  `hover:bg-white/70`,
  `group-hover/card:bg-primary-500`,
  `group-hover/card:bg-secondary-500`,
  `group-hover/card:bg-white`,
  `group-hover/card:bg-transparent`,
  `group-hover/card:bg-secondary-500/10`,
  `group-hover/card:bg-white/10`,
  `group-hover/card:bg-white/80`,
  `group-hover/card:bg-white/70`,
  `group-hover/card:translate-x-1`,
  `group-hover/card:translate-y-[-4px]`,
]

</script>

<template>
  <NuxtLink
    class="text-md relative rounded-full border py-2"
    :class="[variantClass, variant != ContentButtonVariant.Arrow ? 'px-6' : '']"
  >
    <slot />

    <span
      class="hidden"
      :class="prerenderClasses"
    />

    <Icon
      v-if="variant == ContentButtonVariant.Arrow"
      name="ph:arrow-up-right"
      class="bg-secondary-500 ml-4 size-8 rounded-full p-2 text-white transition-all duration-300 ease-out"
      :class="`${hoverPrefix}bg-secondary-500 ${hoverPrefix}translate-x-1 ${hoverPrefix}translate-y-[-4px]`"
    />
  </NuxtLink>
</template>
