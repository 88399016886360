import { type AnyObjectSchema } from 'yup'

/**
 * Composable to inject and set the block form schema
 * @param schema The Yup schema to set
 * @param name Optional name for debugging
 */
export function useBlockFormSchema(blockSchema: AnyObjectSchema) {
  const blockFormSchema = inject<Ref<AnyObjectSchema>>('blockFormSchema')

  // Merge the block schema with the generic "advanced settings" schema
  const mergedSchema = blockSchema.concat(advancedSettingsSchema)

  // Inject the merged schema
  if (blockFormSchema) {
    blockFormSchema.value = mergedSchema
    // console.log(`Set the block schema from child`, mergedSchema)
  }

  return {
    blockFormSchema,
  }
}
