<script setup lang="ts">
// TODO:
// - SVG's with non-square dimensions are not displayed correctly

const props = defineProps<{
  // Prop to override the global color mode
  colorMode?: 'light' | 'dark'
}>()

const globoalColorMode = useColorMode()

const { appName } = useAppName()

const { currentCenterProfile } = useCurrentCenter()

const isDarkMode = computed(() => {
  return props.colorMode == 'dark' || globoalColorMode.value == 'dark'
})

const key = ref(0)

const logoMarkUrl = computed(() => {
  if (isDarkMode.value && currentCenterProfile.value.logoMarkDarkModeUrl) {
    return currentCenterProfile.value.logoMarkDarkModeUrl
  }
  return currentCenterProfile.value.logoMarkUrl
})

// Quickfix for dark mode on initial page load, seems to be a bug in useColorMode?
onMounted(() => {
  key.value++
})

</script>

<template>
  <img
    v-if="logoMarkUrl"
    :src="logoMarkUrl"
    :alt="`Logo beeldmark ${appName}`"
  >
</template>
