<script setup lang="ts">
const contentBlockSpacing = inject('contentBlockSpacing', 'space-y-6')
</script>

<template>
  <div>
    <slot name="title" />
    <div class="gap-x-10 pb-10" :class="contentBlockSpacing">
      <slot />
    </div>
  </div>
</template>
