<script setup lang="ts">
// TODO: what's the type of contentBlockType?
const props = defineProps<{ contentBlockType: any }>()

const { contentBlockFormMap } = useContentBlockMaps()
const formComponent = contentBlockFormMap[props.contentBlockType]
</script>

<template>
  <div>
    <component :is="formComponent" />

    <ContentBlockFormPartialAdvancedSettings />
  </div>
</template>
