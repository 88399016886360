import { boolean, mixed, object, string, type InferType } from 'yup'

export const photoHeroSchema = object({
  // The background image
  image: mixed<File | ContentFile>().required(),
  // The text overlay
  slogan: string().required(),
  // Whether to show the logo overlay
  showLogo: boolean().default(true),
})

export interface PhotoHeroPayload extends InferType<typeof photoHeroSchema> {}
