<script setup lang="ts">
import { object, string, number, array } from 'yup'
import { ContentButtonVariant } from '#imports'

const { t } = useI18n({ useScope: 'local' })

// Define the image schema
const backgroundImageSchema = object({
  image: imageUploadField.required(),
  altText: string(),
})

const heroWithSearchSchema = object({
  title: string().required(),
  caption: string().required(),
  ctaText: string().required(),
  ctaLink: string().required(),
  ctaButtonVariant: string().oneOf(Object.values(ContentButtonVariant)).default(ContentButtonVariant.Primary),
  backgroundImages: array(backgroundImageSchema).min(1).required(),
  backgroundOffset: number().min(0).max(50).default(20).nullable(),
  transitionInterval: number().min(3).max(10).default(5).nullable(),
  searchTitle: string().required(),
  searchButtonText: string().required(),
})

// Set up field array for background images
const { fields, push, remove } = useFieldArray('backgroundImages')

const pushNew = () => {
  push({ image: null, altText: '' })
}

// Ensure at least one image field is available
onMounted(() => {
  if ((fields.value || []).length === 0) {
    pushNew()
  }
})

// Inject the block form schema
useBlockFormSchema(heroWithSearchSchema)
</script>

<template>
  <div class="flex flex-col gap-y-4 pt-4">
    <BaseParagraph class="mt-0">
      {{ t('explanation') }}
    </BaseParagraph>

    <FormField
      name="title"
      :label="t('title')"
    />
    <FormField
      name="caption"
      type="textarea"
      :label="t('caption')"
    />

    <div class="border-primary-500 flex flex-col gap-y-4 rounded-xl border p-4">
      <BaseHeading
        tag="h4"
        size="lg"
        weight="medium"
      >
        {{ t('ctaSection') }}
      </BaseHeading>

      <FormField
        name="ctaText"
        :label="t('ctaText')"
      />
      <FormField
        name="ctaLink"
        :label="t('ctaLink')"
      />
      <FormListField
        name="ctaButtonVariant"
        :label="t('ctaButtonVariant')"
        :items="Object.values(ContentButtonVariant)"
        :item-label="(item: ContentButtonVariant) => t(`content.buttonVariant.${item}`)"
      />
    </div>

    <div class="border-primary-500 flex flex-col gap-y-4 rounded-xl border p-4">
      <ContentBlockFormPartialRepeatable
        :title="t('backgroundSection')"
        :empty-message="t('noImagesMessage')"
        :items="fields"
        @add="pushNew"
      >
        <template #default="{ index }">
          <ContentBlockFormPartialRepeatableItem
            v-if="index !== undefined"
            :index="index"
            :title="t('backgroundImageTitle')"
            @remove="remove(index)"
          >
            <div class="space-y-4">
              <FormImageField
                :name="`backgroundImages[${index}].image`"
                :label="t('backgroundImage')"
              />
              <FormField
                :name="`backgroundImages[${index}].altText`"
                :label="t('backgroundImageAltText')"
                :placeholder="t('backgroundImageAltTextPlaceholder')"
              />
            </div>
          </ContentBlockFormPartialRepeatableItem>
        </template>

        <template #add-button-text>
          {{ t('addBackgroundImage') }}
        </template>
      </ContentBlockFormPartialRepeatable>

      <FormField
        name="backgroundOffset"
        type="number"
        :label="t('backgroundOffset')"
        min="0"
        max="50"
      />

      <FormField
        name="transitionInterval"
        type="number"
        :label="t('transitionInterval')"
        min="3"
        max="10"
      />
    </div>

    <div class="border-primary-500 flex flex-col gap-y-4 rounded-xl border p-4">
      <BaseHeading
        tag="h4"
        size="lg"
        weight="medium"
      >
        {{ t('searchSection') }}
      </BaseHeading>

      <FormField
        name="searchTitle"
        :label="t('searchTitle')"
      />
      <FormField
        name="searchButtonText"
        :label="t('searchButtonText')"
      />
    </div>
  </div>
</template>

<i18n lang="json">
{
  "en": {
    "explanation": "With this block you can create a hero section with background images, title, and search functionality.",
    "title": "Main Title/Slogan",
    "caption": "Caption Text",
    "ctaSection": "Call to Action",
    "ctaText": "CTA Button Text",
    "ctaLink": "CTA Button Link",
    "ctaButtonVariant": "CTA Button style",
    "backgroundSection": "Background Images",
    "backgroundImageTitle": "Background Image",
    "backgroundImage": "Image",
    "backgroundOffset": "Background offset (0-50%)",
    "transitionInterval": "Transition interval in seconds (optional)",
    "searchSection": "Search Area",
    "searchTitle": "Search Title",
    "searchButtonText": "Search Button Text",
    "backgroundImageAltText": "Alternative text for the background image",
    "backgroundImageAltTextPlaceholder": "Describe the image",
    "noImagesMessage": "You haven't added any background images yet.",
    "addBackgroundImage": "Add background image"
  },
  "nl": {
    "explanation": "Met dit blok maak je een hero-sectie met achtergrondafbeeldingen, titel en zoekfunctionaliteit.",
    "title": "Hoofdtitel/Slogan",
    "caption": "Ondertitel",
    "ctaSection": "Call to Action (CTA)",
    "ctaText": "CTA knop tekst",
    "ctaLink": "CTA knop link",
    "ctaButtonVariant": "CTA knop stijl",
    "backgroundSection": "Achtergrondafbeeldingen",
    "backgroundImageTitle": "Achtergrondafbeelding",
    "backgroundImage": "Afbeelding",
    "backgroundOffset": "Achtergrond verschuiving (0-50%)",
    "transitionInterval": "Overgangsinterval in seconden (optioneel)",
    "searchSection": "Zoekgedeelte",
    "searchTitle": "Titel zoekformulier (optioneel)",
    "searchButtonText": "Zoek knop tekst",
    "backgroundImageAltText": "Alternatieve tekst voor de achtergrondafbeelding",
    "backgroundImageAltTextPlaceholder": "Beschrijf de afbeelding",
    "noImagesMessage": "Je hebt nog geen achtergrondafbeeldingen toegevoegd.",
    "addBackgroundImage": "Voeg achtergrondafbeelding toe"
  }
}
</i18n>
