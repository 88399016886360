<script setup lang="ts">

import { useBlockFormSchema } from '~/composables/useBlockFormSchema'

useBlockFormSchema(hotlinksSchema)

const { t } = useI18n({ useScope: 'local' })

const { fields: sections, push: addSection, remove: removeSection } = useFieldArray<HotlinkSectionPayload[]>('sections')

function addNewSection() {
  addSection({
    // @ts-ignore no idea, weird typing error
    title: '',
    color: 'primary',
    hotlinks: [],
  })
}

</script>

<template>
  <div class="flex flex-col gap-y-4 pt-4">
    <BaseParagraph class="mt-0">
      {{ t('explanation') }}
    </BaseParagraph>

    <div class="flex flex-col gap-y-4">
      <div
        v-for="(section, index) in sections"
        :key="index"
        class="border-primary-500 relative flex flex-col gap-y-3 rounded-xl border p-4"
      >
        <div class="flex items-center justify-between">
          <BaseHeading as="h3" class="text-lg font-bold">
            {{ t('section', { number: index + 1 }) }}
          </BaseHeading>

          <BaseButtonIcon
            color="danger"
            @click="removeSection(index)"
          >
            <Icon name="lucide:trash" class="size-4" />
          </BaseButtonIcon>
        </div>

        <FormField
          :name="`sections.${index}.title`"
          :label="t('sectionTitle')"
        />

        <FormListField
          :name="`sections.${index}.color`"
          :items="['primary', 'secondary']"
          :label="t('color')"
          :label-factory="(item: string) => t(`colors.${item}`)"
          type="select"
        />

        <div class="space-y-4">
          <BaseHeading as="h4" class="text-base font-semibold">
            {{ t('hotlinks') }}
          </BaseHeading>

          <FieldArray v-slot="{ fields, push, remove }" :name="`sections.${index}.hotlinks`">
            <div
              v-for="(entry, idx) in fields"
              :key="entry.key"
              class="flex flex-col gap-y-3 rounded-lg border border-gray-200 p-4"
            >
              <FormField
                :name="`sections.${index}.hotlinks[${idx}].title`"
                type="text"
                :label="t('linkTitle')"
              />
              <FormField
                :name="`sections.${index}.hotlinks[${idx}].url`"
                type="url"
                :label="t('url')"
              />

              <BaseButton color="danger" @click="remove(idx)">
                {{ t('remove') }}
              </BaseButton>
            </div>

            <BaseButton @click="push({ title: '', url: '' })">
              {{ t('addLink') }}
            </BaseButton>
          </FieldArray>
        </div>
      </div>
    </div>

    <BaseButton
      @click="addNewSection"
    >
      {{ t('addSection') }}
    </BaseButton>
  </div>
</template>

<i18n lang="json">
{
  "en": {
    "explanation": "Create two sections with hotlinks. Each section can have a title, color, and multiple links. It's most logical to create two sections, with each having 3~6 links.",
    "section": "Section {number}",
    "sectionTitle": "Section Title",
    "color": "Color",
    "colors": {
      "primary": "Primary",
      "secondary": "Secondary"
    },
    "hotlinks": "Links",
    "link": "Link {number}",
    "linkTitle": "Link Title",
    "url": "URL",
    "addSection": "Add Section",
    "addLink": "Add Link",
    "remove": "Remove"
  },
  "nl": {
    "explanation": "Maak twee secties met snelkoppelingen. Elke sectie kan een titel, kleur en meerdere links hebben. Het is het meest logisch om twee secties te maken, met ieder max 3~6 links.",
    "section": "Sectie {number}",
    "sectionTitle": "Sectie Titel",
    "color": "Kleur",
    "colors": {
      "primary": "Primair",
      "secondary": "Secundair"
    },
    "hotlinks": "Links",
    "link": "Link {number}",
    "linkTitle": "Link Titel",
    "url": "URL",
    "addSection": "Sectie Toevoegen",
    "addLink": "Link Toevoegen",
    "remove": "Verwijderen"
  }
}
</i18n>
