<script setup lang="ts">
const props = defineProps<{
  formId: id
  alignLeft?: boolean
}>()

const { data: form } = fetchForm(props.formId)

const submitted = ref(false)

function handleSubmitted(values: Record<string, any>) {
  console.log(values)
  submitted.value = true
}
</script>

<template>
  <ContentBlockViewPartialContainer>
    <FormBuilderForm
      v-if="form && !submitted"
      :form="form"
      :align-left="alignLeft"
      @submit="handleSubmitted"
    />

    <BaseCard v-else class="mb-4 max-w-lg p-4 sm:mx-auto sm:max-w-xl">
      <PageTitle class="mb-4">
        Formulier verzonden
      </PageTitle>

      <BaseMessage type="success" icon>
        Bedankt voor je bericht. Wij nemen zo snel mogelijk contact met je op.
      </BaseMessage>
    </BaseCard>
  </ContentBlockViewPartialContainer>
</template>
