<script setup lang="ts">
import { DynamicCardMediaType, DynamicCardComponentStyle, ContentButtonVariant } from '#imports'

// TODO:
// - Make it possible to show the image full height
// - Show a preview of the component in the form modal

useBlockFormSchema(dynamicCardsSchema)

const { fields, push, remove }
  = useFieldArray<Partial<DynamicCardPayload>>('cards')

const { t } = useI18n({ useScope: 'local' })

const { value: isCarousel } = useField<boolean>('isCarousel')

// Toggle for the explanation section
const showExplanation = ref(false)
</script>

<template>
  <div class="flex flex-col gap-y-4 pt-4">
    <BaseMessage type="info">
      {{ t('shortExplanation') }}
    </BaseMessage>

    <div class="flex items-center gap-x-2">
      <BaseButton
        color="info"
        shape="curved"
        muted
        @click="showExplanation = !showExplanation"
      >
        <Icon :name="showExplanation ? 'ph:info-fill' : 'ph:info'" class="mr-2 size-5" />
        {{ t('explanationTitle') }}
      </BaseButton>
    </div>

    <BaseCard
      v-if="showExplanation"
      color="info"
      class="p-4"
    >
      <BaseParagraph>
        {{ t('explanationCardText') }}
      </BaseParagraph>

      <ul class="mt-4 list-disc pl-4">
        <li>
          {{ t('explanationStyles') }}
        </li>
        <li>
          {{ t('explanationTitleOptional') }}
        </li>
        <li>
          {{ t('explanationTextOptional') }}
        </li>
        <li>
          {{ t('explanationImageOptional') }}
        </li>
        <li>
          {{ t('explanationCarousel') }}
        </li>
        <li>
          {{ t('explanationNoCarousel') }}
        </li>
        <li>
          {{ t('explanationCardHeight') }}
        </li>
      </ul>
    </BaseCard>

    <FormField :name="`title`" :label="t('titleLabel')" />

    <FormListField
      name="styling"
      :items="Object.values(DynamicCardComponentStyle)"
      :label="t('stylingLabel')"
      :label-factory="(option) => t(`stylingOptions.${option}`)"
    />

    <FormListField
      name="headingSize"
      :items="Object.values(['md', 'xl'])"
      :label="t('headingSizeLabel')"
      :label-factory="(option) => t(`headingSizeOptions.${option}`)"
    />

    <FormListField
      name="buttonVariant"
      :items="Object.values(ContentButtonVariant)"
      :label="t('buttonVariantLabel')"
      :label-factory="(option) => t(`buttonVariantOptions.${option}`)"
    />

    <BaseSwitchThin
      v-model="isCarousel"
      :label="t('carouselLabel')"
      :sublabel="t('carouselSublabel')"
    />

    <FormField
      v-if="!isCarousel"
      name="maxCardsPerRow"
      :label="t('maxCardsPerRowLabel')"
      type="number"
    />

    <BaseMessage v-if="fields.length === 0" type="info">
      {{
        t('noCardsAdded')
      }}
    </BaseMessage>

    <div
      v-for="(card, index) in fields"
      :key="index"
      class="border-primary-500 relative flex flex-col gap-y-3 rounded-xl border p-4"
    >
      <div class="flex items-center justify-between">
        <BaseHeading
          tag="h4"
          size="lg"
          weight="medium"
        >
          {{ t('card') }} {{ index + 1 }}
        </BaseHeading>

        <BaseButtonIcon
          color="danger"
          shape="curved"
          muted
          class="scale-75"
          @click="remove(index)"
        >
          <Icon name="ph:trash" class="size-5" />
        </BaseButtonIcon>
      </div>

      <FormListField
        :name="`cards[${index}].mediaType`"
        :items="Object.values(DynamicCardMediaType)"
        :label="t('mediaTypeLabel')"
        :label-factory="(option) => t(`mediaTypeOptions.${option}`)"
      />

      <FormField
        v-if="card.value.mediaType == 'youtube'"
        :name="`cards[${index}].youtubeUrl`"
        label="YouTube URL"
        keep-value
      />
      
      <FormImageField
        v-if="card.value.mediaType == 'image'"
        :name="`cards[${index}].image`"
        label="Afbeelding"
        keep-value
      />

      <FormField :name="`cards[${index}].title`" :label="t('cardTitleLabel')" />
      <FormField :name="`cards[${index}].subTitle`" :label="t('cardSubtitleLabel')" />
      <FormTextarea :name="`cards[${index}].body`" :label="t('cardTextLabel')" />
      <FormField :name="`cards[${index}].buttonText`" :label="t('buttonTextLabel')" />
      <FormField :name="`cards[${index}].buttonUrl`" :label="t('buttonUrlLabel')" />
    </div>

    <BaseButton
      color="primary"
      @click="push({ title: undefined, body: undefined, mediaType: undefined })"
    >
      {{ t('addCard') }}
    </BaseButton>
  </div>
</template>

<i18n lang="json">
{
  "en": {
    "shortExplanation": "This is a section where you can add as many cards as you want.",
    "explanationTitle": "How do Dynamic Cards work?",
    "explanationCardText": "With this content block you can add cards in various styles. You can choose between different styles, and it's good to know that not all fields are required. For example, if you don't add any text but only an image and link, you'll create a photo-button.",
    "explanationStyles": "There are 3 different styles, and you can also choose between different button variants.",
    "explanationTitleOptional": "The title is optional.",
    "explanationTextOptional": "The text is optional.",
    "explanationImageOptional": "The image is optional.",
    "explanationCarousel": "You can choose between a carousel, where cards scroll, or a fixed number of cards per row.",
    "explanationNoCarousel": "If you don't choose a carousel, you get a fixed number of cards per row.",
    "explanationCardHeight": "Card heights adapt to content. Regular cards (with header image) maintain a 16:9 aspect ratio for images. Photo background cards have a minimum height of 384px.",
    "card": "Card",
    "noCardsAdded": "You haven't added any cards yet.",
    "carouselLabel": "Carousel",
    "carouselSublabel": "Display as a carousel with scroll and swipe functionality.",
    "addCard": "Add a card",
    "mediaTypeLabel": "Choose media type",
    "maxCardsPerRowLabel": "Maximum number of cards per row",
    "headingSizeLabel": "Heading size",
    "headingSizeOptions": {
      "md": "Medium",
      "xl": "Large"
    },
    "buttonVariantLabel": "Button variant",
    "buttonVariantOptions": {
      "primary": "Primary",
      "secondary": "Secondary",
      "outline": "Outline",
      "outline-secondary": "Outline secondary",
      "light": "Light",
      "white": "White",
      "arrow": "Arrow"
    },
    "mediaTypeOptions": {
      "no-media": "No video or image",
      "image": "Image",
      "youtube": "YouTube video"
    },
    "stylingLabel": "Choose style",
    "stylingOptions": {
      "default": "Standard (image as header)",
      "photo-background": "Image as background",
      "dark-background": "Dark (with image as header)",
      "text-only": "Text (no image)"
    },
    "titleLabel": "Title (optional)",
    "cardTitleLabel": "Title",
    "cardSubtitleLabel": "Subtitle",
    "cardTextLabel": "Text",
    "buttonTextLabel": "Button text",
    "buttonUrlLabel": "Button URL"
  },
  "nl": {
    "shortExplanation": "Dit is een sectie waarin je een rij horizontale kaarten (blokjes) kunt toevoegen.",
    "explanationTitle": "Hoe werken Dynamische Kaarten?",
    "explanationCardText": "Met dit blok kun je veel varianten componenten maken. Je kunt kiezen voor verschillende kaart-stijlen en knop-variantenen. Daarnaast is het goed om te weten dat niet alle velden verplicht zijn. Als je bijvoorbeeld geen tekst toevoegt, maar alleen een afbeelding en link, dan krijg je een heel ander soort component dan wanneer je titels en teksten invoert.",
    "explanationStyles": "Er zijn verschillende stijlen en verschillende knop-varianten.",
    "explanationTitleOptional": "De titel is optioneel.",
    "explanationTextOptional": "De tekst is optioneel.",
    "explanationImageOptional": "De afbeelding is optioneel.",
    "explanationCarousel": "Je kunt kiezen voor een carousel, waarbij de kaarten scrollen, of een vast aantal kaarten per rij.",
    "explanationNoCarousel": "Als je geen carousel kiest, krijg je een vast aantal kaarten per rij.",
    "explanationCardHeight": "De hoogte van kaarten past zich aan aan de inhoud. Standaard kaarten (met afbeelding als header) behouden een 16:9 beeldverhouding voor afbeeldingen. Kaarten met afbeelding als achtergrond hebben een minimale hoogte van 384px.",
    "card": "Kaart",
    "noCardsAdded": "Je hebt nog geen kaarten toegevoegd.",
    "carouselLabel": "Carousel",
    "carouselSublabel": "Weergeven als carousel met scroll- en swipefunctionaliteit.",
    "addCard": "Voeg een kaart toe",
    "mediaTypeLabel": "Kies media type",
    "maxCardsPerRowLabel": "Maximaal aantal kaarten per rij (kies 2, 3 of 4)",
    "headingSizeLabel": "Kaart titel tekstgrootte",
    "headingSizeOptions": {
      "md": "Medium",
      "xl": "Groot"
    },
    "buttonVariantLabel": "Button variant",
    "buttonVariantOptions": {
      "primary": "Primary",
      "secondary": "Secondary",
      "outline": "Outline",
      "outline-secondary": "Outline secondary",
      "light": "Light",
      "white": "White",
      "arrow": "Arrow"
    },
    "mediaTypeOptions": {
      "no-media": "Geen video of afbeelding",
      "image": "Afbeelding",
      "youtube": "YouTube video"
    },
    "stylingLabel": "Kies stijl",
    "stylingOptions": {
      "default": "Standaard (afbeelding als header)",
      "photo-background": "Afbeelding als achtergrond",
      "dark-background": "Donker (met afbeelding als header)",
      "text-only": "Tekst (geen afbeelding)"
    },
    "titleLabel": "Titel (optioneel)",
    "cardTitleLabel": "Titel",
    "cardSubtitleLabel": "Subtitel",
    "cardTextLabel": "Tekst",
    "buttonTextLabel": "Button tekst",
    "buttonUrlLabel": "Button URL"
  }
}
</i18n>
