import { object, number, boolean } from 'yup'

export const advancedSettingsSchema = object({
  settings: object({
    marginTop: object({
      desktop: number().optional().default(null),
      mobile: number().optional().default(null),
    }).nullable().default(null),
    marginBottom: object({
      desktop: number().optional().default(null),
      mobile: number().optional().default(null),
    }).nullable().default(null),
    hideComponentOnMobile: boolean().optional().default(false),
  }).nullable().default(null),
})
