<script setup lang="ts">
const pageHasSideColumn = inject<boolean>('pageHasSideColumn', false)

// For meedoen and stagedoos, we never want to center.
// (basically, we only want to center for Nijmegen and partners - for now)
const isMeedoen = useRuntimeConfig().public.isMeedoen
const isStagedoos = useAppConfig().app.isStagedoos

const shouldCenter = !pageHasSideColumn && !isMeedoen && !isStagedoos

</script>

<template>
  <div
    class="flex"
    :class="{ 'justify-center': shouldCenter }"
  >
    <div class="w-full max-w-2xl">
      <slot />
    </div>
  </div>
</template>
