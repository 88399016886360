<script setup lang="ts">
const props = defineProps<{
  text?: string
  usps?: UspPayload[]
  quote?: QuoteItem
  buttonText: string
  buttonUrl: string
}>()

const hasLeftContent = computed(() => props.text || (props.usps && props.usps.length > 0))
const shouldSplit = computed(() => hasLeftContent.value && props.quote)

const { cardRadiusClass } = useThemeClasses()
</script>

<template>
  <ContentBlockViewPartialContainer background-color="white" inner-padding>
    <div
      class="flex flex-col gap-8"
      :class="{
        'md:grid md:grid-cols-2': shouldSplit,
        'items-center text-center': !shouldSplit,
      }"
    >
      <ContentBlockViewPartialTitle
        v-if="text"
        :title="text"
      />

      
      <div
        class="flex flex-col gap-y-8"
        :class="{ 'mx-auto max-w-2xl': !shouldSplit }"
      >
        <ul v-if="usps?.length" class="space-y-4">
          <li
            v-for="usp in usps"
            :key="usp.title"
            class="bg-primary-50/25 text-primary-500 flex items-start gap-x-3 rounded-xl px-4 py-2 dark:text-white"
            :class="{ 'justify-center': !shouldSplit, [cardRadiusClass]: true }"
          >
            <!-- <div class="text-primary-500 mt-1">
              <Icon name="lucide:check-circle-2" class="size-5" />
            </div> -->
            <span class="text-lg">{{ usp.title }}</span>
          </li>
        </ul>

        <NuxtLink
          :to="buttonUrl"
          class="bg-primary-500 hover:bg-primary-600 inline-flex items-center justify-center gap-x-2 rounded-xl px-4 py-3 text-center font-semibold text-white transition"
        >
          {{ buttonText }}
          <Icon name="lucide:arrow-right" class="size-5" />
        </NuxtLink>
      </div>

      
      <ContentBlockViewPartialQuote
        v-if="quote"
        :quotes="[quote]"
        color="primary"
      />
    </div>
  </ContentBlockViewPartialContainer>
</template>
