import { object, string, array, type InferType } from 'yup'

export enum YouTubeWidth {
  Large = 'large',
  Medium = 'medium',
  Small = 'small',
}

// Schema for individual YouTube video
export const youtubeVideoSchema = object({
  url: string()
    .required('YouTube URL is required')
    .matches(
      /^(http(s)?:\/\/)?((w){3}.)?youtu(be|.be)?(\.com)?\/.+/,
      'Enter a valid YouTube URL',
    ),
  title: string().optional(),
  description: string().optional(),
  width: string()
    .optional()
    .oneOf(Object.values(YouTubeWidth), 'Invalid width option')
    .required('Width is required'),
})

// Schema for the entire component
export const youtubeBlockSchema = object({
  youtubeVideos: array()
    .of(youtubeVideoSchema)
    .min(1, 'At least one video is required')
    .required(),
})

export interface YouTubeVideo extends InferType<typeof youtubeVideoSchema> {}
export interface YouTubeBlockPayload extends InferType<typeof youtubeBlockSchema> {}
