export enum ContentButtonVariant {
  Primary = 'primary',
  Secondary = 'secondary',
  Outline = 'outline',
  OutlineSecondary = 'outline-secondary',
  Light = 'light',
  White = 'white',
  Arrow = 'arrow',
  // TODO
  // Tertiary = 'tertiary',
}
